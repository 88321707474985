
function myTest() {
    alert('start');
    
    window.addEventListener('load', main, false);

    function main() {
      let list = document.querySelectorAll('.list-item');
      let map = new Map();

      for (let i = 0, maxi = list.length; i < maxi; i += 1) {
        let name = list[i].children[0].textContent;
        let value = list[i].children[1].textContent;
        if ( map.has( name.toLowerCase() ) ) {
          if ( value.trim() === 'Rate Inclusive' ) {
            list[i].remove();
          }
        } else {
          map.set( name.toLowerCase(), true );
        }
      }
    }
}


 

function myrest(val) {
 console.log('ahhhhhhhhhhhhhhhh');
 var progress = document.getElementById("red-bar");
 RPGUI.set_value(progress, val);
 console.log(progress);
 console.log(val);
 console.log(RPGUI.set_value(progress, val));
 console.log(RPGUI.author);

}

function getToday(){
    alert(new Date().toLocaleDateString());
}

function greetings(name){
    alert('welcome ${name}');

    
}

function beacons(name){
    alert('welcome ${name}');

    
}

function greet(){
    alert("Hello");
}
    // alert("Popddddddddddddd up");


